import { PublicExtraTimeControl } from '@store/context/_types';
import { getTimeControlTime } from '@utils/_getTimeControlTime';
import { adaptPublicExtraTimeControl } from '@utils/adapters/_adaptPublicExtraTimeControl';
import { ITimeControl, ILocalization } from '@types';

export function getPublicExtraTimeControlTitle(
  timeControl: PublicExtraTimeControl | undefined,
  l: ILocalization
) {
  return getTimeControlTitle(adaptPublicExtraTimeControl(timeControl), l);
}

export function getTimeControlTitle(
  timeControl:
    | Pick<ITimeControl, 'board_type' | 'start_time' | 'increment'>
    | undefined,
  l: ILocalization
) {
  if (!timeControl) return '';

  const { time, hasIncrement } = getTimeControlTime(timeControl, l);

  if (time.length > 1 && !hasIncrement)
    return time.map(({ value, title }) => `${value} ${title}`).join(' ');
  if (hasIncrement) return time.map((item) => item?.value).join('+');
  return `${time[0]?.value} ${time[0]?.title}`;
}
