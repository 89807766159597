import { useCallback, useMemo } from 'react';

import { useApplicationContext } from '@application';

import { eFideIdStatus, eAccessLevel } from '@types';

import { useAuthStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';

import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { openPopup } from '@utils/_router';
import { ePopupPaths } from '@constants';
import { StaButtonType } from '@chessarena/components/lib/general/StaButton/types';
import { ePaywallPopupType } from '@components/molecules/popups/PaywallPopup/_types';

export const useCustomHeaderButton = () => {
  const { localization: l } = useApplicationContext();

  const fideIdStatus = useFideIdStatus();

  const userData = useUserDataStore('data');
  const userDataRequest = useUserDataStore('data_request');
  const logged = useAuthStore('logged');
  const accessLevel = userData?.access_level;

  const action = useMemo(() => {
    if (userDataRequest) return null;

    if (!logged)
      return {
        label: l.header.button.register,
        type: 'register' as StaButtonType,
      };

    if (accessLevel !== eAccessLevel.PRO) {
      return {
        label: l.header.button.upgrade,
        type: 'payment' as StaButtonType,
      };
    }

    if (accessLevel === eAccessLevel.PRO) {
      if (fideIdStatus === eFideIdStatus.NOT_CONNECTED)
        return {
          label: l.header.button.fide_id,
          type: 'foa' as StaButtonType,
        };
      return null;
    }

    return null;
  }, [userDataRequest, logged, l.header.button, accessLevel, fideIdStatus]);

  const onButtonClick = useCallback(() => {
    if (userDataRequest) return null;

    if (!logged) {
      openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
      return;
    }

    if (accessLevel !== eAccessLevel.PRO) {
      openPopup(
        `?popup=${ePopupPaths.PAYWALL}&type=${ePaywallPopupType.GAMES}`
      );
      return;
    }

    if (accessLevel === eAccessLevel.PRO) {
      if (fideIdStatus === eFideIdStatus.NOT_CONNECTED) {
        openPopup(`?popup=${ePopupPaths.FIDE}`);
        return;
      }
    }
  }, [accessLevel, fideIdStatus, logged, userDataRequest]);

  if (!action) return undefined;

  return {
    ...action,
    onClick: onButtonClick,
  };
};
