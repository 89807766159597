import {
  GameRatingMode,
  IBoardData,
  IGameMove,
  IGamePlayer,
  ITimeControl,
} from '@types';

export const playerTypes = { me: 'me', opponent: 'opponent' };

export type TPlayerType = keyof typeof playerTypes;

export const enum eGamePhase {
  /**
   * Фаза начала игры
   * показывается таймер до проигрыша партии или плашка об ожидании хода соперника, кнопка отмены
   */
  START,

  /**
   * Фаза игры
   * в прогрессе, показывает кнопки ничьи, resign
   */
  IN_PROGRESS,

  /**
   * Фаза конца игры
   * показывает всё связанное с концом игры
   */
  END,
}

export type TGameMoveMsSpent = Pick<IGameMove, 'ms_spent'>;

export interface ICurrentSettings {
  rating: GameRatingMode;
  timeControl: ITimeControl;
}

export interface INextGameData {
  opponent: IGamePlayer;
  boardId: string;
  datetimeOfRound: string | number;
}

export enum eNextGameStatus {
  NEXT_ROUND_OPPONENT = 'NEXT_ROUND_OPPONENT',
  WAITING_NEXT_OPPONENT = 'WAITING_NEXT_OPPONENT',
  SEARCH_NEW_ARENA_GAME = 'SEARCH_NEW_ARENA_GAME',
}

interface INextRoundOpponent {
  status: eNextGameStatus.NEXT_ROUND_OPPONENT;
  data: INextGameData;
}

interface IWaitingNextOpponent {
  status: eNextGameStatus.WAITING_NEXT_OPPONENT;
}

interface ISearchNewArenaGame {
  status: eNextGameStatus.SEARCH_NEW_ARENA_GAME;
}

export type TNextGameStatus =
  | IWaitingNextOpponent
  | INextRoundOpponent
  | ISearchNewArenaGame;

export interface IGameReactionsItem {
  id: string;
  icon: string;
  animation: string;
}

export interface IGameReactionsItemAnimation {
  id: string;
  data: object;
}

export interface IGameReactionsData {
  id: string;
  items: IGameReactionsItem[];
}

export interface IGamePageProps {
  id: string;
  data?: IBoardData;
  reactions: IGameReactionsData;
}
