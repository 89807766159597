import { useApplicationContext } from '@application';
import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import { getRatingTypeText } from '@utils/_getRatingTypeText';
import { getPublicExtraTimeControlTitle } from '@utils/timeControls/_getTimeControlTitle';
import { useActiveGamePublicExtra } from './_useActiveGamePublicExtra.hook';
import { capitalizeString } from '@utils/_helpers/_common';

export const useTimeControlData = (gameData: ILobbyContextGame | null) => {
  const { localization: l } = useApplicationContext();

  const { ratings: ratingsLocalization } = l;

  const publicExtra = useActiveGamePublicExtra(gameData);

  const timeControl = publicExtra?.time_control;

  if (!gameData || !timeControl) {
    return null;
  }

  const timeControlTypeName = capitalizeString(
    timeControl?.board_type_name ?? ''
  );

  const time = getPublicExtraTimeControlTitle(timeControl, l);

  const rating = getRatingTypeText(publicExtra.rating, ratingsLocalization);

  return {
    timeControlTypeName,
    time,
    rating,
    ratingType: publicExtra.rating,
  };
};
