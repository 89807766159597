import { formatLabel } from '@utils/_formatLabel';
import { TimeControlTime } from '@chessarena/components/lib/general/TimeControlTime/types';
import { BoardType, ILocalization, ITimeControl } from '@types';

type TTimeItem = { d: number; h: number; m: number; s: number };

const getParsedTime = (v: string) => {
  let d = '',
    h = '',
    m = '',
    s = '';

  if (/ /.test(v)) {
    const [$d, $hms] = v.split(' ');
    const [$h, $m, $s] = $hms.split(':');
    d = $d;
    h = $h;
    m = $m;
    s = $s;
  } else {
    const [$h, $m, $s] = v.split(':');
    h = $h;
    m = $m;
    s = $s;
  }

  return {
    d: +d,
    h: +h,
    m: +m,
    s: +s,
  };
};

const getTimeItem = (
  data: TTimeItem,
  l: ILocalization,
  showTitle: boolean,
  longTitle: boolean
) => {
  const { d, h, m, s } = data;
  const key = longTitle ? 'long_plural' : 'short';
  const result: TimeControlTime = [] as unknown as TimeControlTime;
  if (d)
    result.push({
      value: d.toString(),
      title: showTitle
        ? formatLabel(l.common.time_control_time[key].day, { count: d })
        : null,
    });
  if (h)
    result.push({
      value: h.toString(),
      title: showTitle
        ? formatLabel(l.common.time_control_time[key].hour, { count: h })
        : null,
    });
  if (m)
    result.push({
      value: m.toString(),
      title: showTitle
        ? l.common.time_control_time[result.length > 0 ? 'long_plural' : key]
            .minute
        : null,
    });
  if (s)
    result.push({
      value: s.toString(),
      title: showTitle ? l.common.time_control_time[key].second : null,
    });

  return result;
};

const getTime = (
  value: string,
  increment: string,
  l: ILocalization,
  isLongTimeControl: boolean
) => {
  const parsedValue = getParsedTime(value);
  const parsedIncrement = getParsedTime(increment);

  const hasIncrement = !!Object.values(parsedIncrement).filter((item) => !!item)
    .length;
  const hasValueItems =
    Object.values(parsedValue).filter((item) => !!item).length > 1;

  const result: TimeControlTime = [] as unknown as TimeControlTime;

  const $value = getTimeItem(
    parsedValue,
    l,
    isLongTimeControl ? isLongTimeControl : !hasIncrement,
    !(hasIncrement || hasValueItems)
  );

  result.push(...$value);

  if (hasIncrement) {
    const $increment = getTimeItem(
      parsedIncrement,
      l,
      isLongTimeControl,
      false
    );
    result.push({ ...$increment[0], increment: true });
  }

  return { time: result, hasIncrement };
};

export const getTimeControlTime = (
  data: Pick<ITimeControl, 'board_type' | 'start_time' | 'increment'>,
  l: ILocalization
) => {
  switch (data.board_type) {
    case BoardType.RAPID:
    case BoardType.BLITZ:
    case BoardType.BULLET:
      return getTime(data.start_time, data.increment, l, false);
    default:
      return getTime(data.start_time, data.increment, l, true);
  }
};
