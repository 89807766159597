import { FC, memo } from 'react';
import GameTimer from '@chessarena/components/lib/game/GameTimer';
import { ICardGameTimerProps } from './types';

export const CardGameTimer: FC<ICardGameTimerProps> = memo(
  function CardGameTimer({ milliseconds, isCurrentTurn }) {
    return (
      <GameTimer
        milliseconds={milliseconds}
        isCurrentPlayerTurn={isCurrentTurn}
      />
    );
  }
);
