import { useMemo } from 'react';
import Link from 'next/link';

import { NotificationsRenderItemLinkParams } from '@chessarena/components/lib/layout/Notifications/types';
import { useNotificationsContext } from '@store/context/notifications/_context';

export const useCustomHeaderNotifications = () => {
  const { captions, onMakeAllAsReadClick, onItemClick, items, unread, count } =
    useNotificationsContext();

  const renderItemLink = (params: NotificationsRenderItemLinkParams) => {
    const { link, children } = params;
    return (
      <Link prefetch={false} href={link}>
        {children}
      </Link>
    );
  };

  const notifications = useMemo(
    () => ({
      captions,
      onMakeAllAsReadClick,
      onItemClick,
      renderItemLink,
      items,
      unread,
      count,
    }),
    [captions, onMakeAllAsReadClick, onItemClick, items, unread]
  );

  return {
    notifications,
  };
};
