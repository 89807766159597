import { useCustomHeaderMenu } from './_useCustomHeaderMenu.hook';
import { useCustomHeaderThemes } from './_useCustomHeaderThemes.hook';
import { useCustomHeaderLocalization } from './_useCustomHeaderLocalization.hook';
import { useCustomHeaderNotifications } from './_useCustomHeaderNotifications.hook';
import { useCustomHeaderAccount } from './_useCustomHeaderAccount.hook';
import { useCustomHeaderChallenges } from './_useCustomHeaderChallenges.hook';
import { useCustomHeaderSubMenu } from './_useCustomHeaderSubMenu.hook';
import { useCustomHeaderActiveGames } from './_useCustomHeaderActiveGames.hook';
import { useApplicationContext } from '@application';
import { useMemo } from 'react';
import { useCustomHeaderSpecialButton } from '@components/molecules/menus/CustomHeader/_useCustomHeaderSpecialButton.hook';
import { useCustomHeaderAuth } from '@components/molecules/menus/CustomHeader/_useCustomHeaderAuth.hook';
import { useCustomHeaderButton } from '@components/molecules/menus/CustomHeader/_useCustomHeaderButton.hook';

export const useCustomHeaderData = () => {
  const { localization: l } = useApplicationContext();
  const { home, menu } = useCustomHeaderMenu();
  const { subMenu } = useCustomHeaderSubMenu();
  const { themes } = useCustomHeaderThemes();
  const { localization } = useCustomHeaderLocalization();
  const { notifications } = useCustomHeaderNotifications();
  const { account } = useCustomHeaderAccount();

  const { challenges, alert: challengesAlert } = useCustomHeaderChallenges();
  const activeGames = useCustomHeaderActiveGames();
  const button = useCustomHeaderButton();
  const specialButton = useCustomHeaderSpecialButton();
  const auth = useCustomHeaderAuth();

  const captions = useMemo(
    () => ({
      goBack: l?.header?.actions?.go_back,
    }),
    [l]
  );

  return {
    home,
    menu,
    subMenu,
    button,
    auth,
    themes,
    localization,
    notifications,
    account,
    challenges,
    challengesAlert,
    activeGames,
    captions,
    specialButton,
  };
};
