import { PublicExtraTimeControl } from '@store/context/_types';
import { convertBoardTypeToBoardTypeName } from '@utils/_convertBoardTypeToBoardTypeName';
import { ITimeControl } from '@types';

export const adaptPublicExtraTimeControl = (
  publicExtraTimeControl: PublicExtraTimeControl | undefined
):
  | Pick<ITimeControl, 'board_type' | 'start_time' | 'increment'>
  | undefined => {
  if (!publicExtraTimeControl) return undefined;

  return {
    increment: publicExtraTimeControl.increment,
    start_time: publicExtraTimeControl.start_time,
    board_type: convertBoardTypeToBoardTypeName(
      publicExtraTimeControl.board_type_name
    ),
  };
};
