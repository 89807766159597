import { useMemo } from 'react';
import { PublicExtraPlayer } from '@store/context/_types';
import { getPlayerTitle } from '@utils/_getPlayerTitle';
import { getAgeNumber } from '@utils/_helpers/_common';
import { getCountryById } from '@utils/_helpers/_get_country_by_id';
import { eAccessLevel } from '@types';

// TODO: Если будем в страницу active-games запихивать турнирные игры,
// то закомментированный код ниже для турнира может пригодится и тогда — вынести этот файл глобально
export const usePlayerInfoData = (player: PublicExtraPlayer | null) => {
  const playerInfo = useMemo(() => {
    if (!player) {
      return null;
    }

    const {
      full_name,
      birth_date,
      avatar,
      nationality_id,
      rating,
      player_id,
      access_level,
    } = player;

    const avatarSrc =
      avatar ||
      'https://gaming-images.worldchess.com/media/default-user-avatar.svg';
    const playerName = full_name || 'Anonymous';
    const playerAge = getAgeNumber(birth_date ?? '');
    const playerCountry = nationality_id
      ? getCountryById(nationality_id)?.long_code.toUpperCase() ?? ''
      : '';
    const playerRating: number | 'New' = rating || 'New';
    const playerTitle = getPlayerTitle(player);
    // const playerInfoSize = 'm';
    const premium = access_level === eAccessLevel.PRO;

    // const tournamentInfo =
    //   isTournament && isPlayer
    //     ? getPlayerTournamentData(player, l?.game_page?.player_info, locale)
    //     : undefined;

    return {
      avatarSrc,
      playerName,
      playerAge,
      playerCountry,
      playerRating,
      playerTitle,
      // playerInfoSize,
      premium,
      playerId: player_id,

      // ...tournamentInfo,
    };
  }, [
    // isTournament,
    // isPlayer,
    // l?.game_page?.player_info,
    // locale,
    player,
  ]);

  return playerInfo;
};
