import { useCallback, useEffect } from 'react';
import { IChallengeItem, ITourGameData, TournamentStatus } from '@types';
import { chessSide } from 'chessgun/core';
import { GameType, GameUserType } from '@types';
import { useNGPageContext } from '../_ngPage.context';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import {
  eLobbyServerPayloadType,
  MessageType,
} from '@store/context/lobby_context/ws/_types';

export const useLobbyWsOnMessage = () => {
  const {
    state: { ws: lobbyWs },
    actions: { getBoardData, setBoarData },
  } = useLobbyContext();
  const {
    actions: {
      getBoardResult,
      getTourBoardResult,
      getGamePgn,
      setNextGameStatus,
      setRematchOffer,
      setInviteChallenge,
    },
    computed: {
      boardId,
      uid,
      opponent,
      gameType,
      myColor,
      boardData,
      gameUserType,
    },
  } = useNGPageContext();

  const onMessage = useCallback(
    (message: MessageType) => {
      const { payload, payloadType } = message;

      switch (payloadType) {
        case eLobbyServerPayloadType.GAMING_BOARD_FINISHED: {
          const isWhitePlayer = myColor === chessSide.WHITE;

          const endedCallback = (data: ITourGameData, isMyBoard: boolean) => {
            if (!isMyBoard) return;

            gameType === GameType.SINGLE_GAME
              ? getBoardResult(boardId)
              : getTourBoardResult(data, isWhitePlayer);
          };

          if (uid) {
            getBoardData({
              boardId,
              playerUid: uid,
              endedUpdate: true,
              endedCallback,
            });
          }

          break;
        }
        case eLobbyServerPayloadType.GAMING_PGN_CREATED: {
          getGamePgn(boardId, payload.pgn_download_name);
          break;
        }
        case eLobbyServerPayloadType.TOURNAMENT_OVER: {
          if (boardData?.tournament.id === payload.tournament_id) {
            setBoarData(boardId, {
              ...boardData,
              tournament: {
                ...boardData.tournament,
                status: TournamentStatus.COMPLETED,
              },
            });

            setNextGameStatus(null);
          }

          break;
        }
        case eLobbyServerPayloadType.CHALLENGE_INVITE_CREATED: {
          if (
            (payload.owner?.uid === opponent?.uid ||
              payload.owner_anonymous_uid === opponent?.uid) &&
            payload.time_control.id === boardData?.time_control.id &&
            gameUserType === GameUserType.PLAYER
          ) {
            setInviteChallenge(payload as IChallengeItem);
          }
          break;
        }

        case eLobbyServerPayloadType.CHALLENGE_INVITE_DELETED: {
          if (
            (payload.owner?.uid === opponent?.uid ||
              payload.owner_anonymous_uid === opponent?.uid) &&
            payload.time_control.id === boardData?.time_control.id
          ) {
            setInviteChallenge(null);
          }
          break;
        }

        case eLobbyServerPayloadType.CHALLENGE_INVITE_DECLINED: {
          if (
            payload.owner?.uid === uid ||
            payload.owner_anonymous_uid === uid
          ) {
            setRematchOffer(null);
          }
          break;
        }

        default:
          break;
      }
    },
    [
      getBoardResult,
      getBoardData,
      getGamePgn,
      uid,
      boardData,
      setRematchOffer,
      gameUserType,
    ]
  );

  useEffect(() => {
    lobbyWs.pageOnMessage = onMessage;
  }, [lobbyWs, onMessage]);

  useEffect(() => {
    return () => {
      lobbyWs.pageOnMessage = () => {};
    };
  }, []);
};
