import { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { SelectPopupRenderItemLinkParams } from '@chessarena/components/lib/general/SelectPopup/types';

import { LocaleType } from '@types';
import { useApplicationContext } from '@application';

import { headerLocalization } from './_constants';

export const useCustomHeaderLocalization = () => {
  const { localization: l, locale, changeLocale } = useApplicationContext();

  const { asPath } = useRouter();

  const renderItemLink = (params: SelectPopupRenderItemLinkParams) => {
    const { link, id, children, ...rest } = params;

    return (
      <Link href={link} locale={id} {...rest}>
        {children as React.ReactNode}
      </Link>
    );
  };

  const localization = useMemo(
    () => ({
      ...headerLocalization,
      items: headerLocalization.items.map((item) => ({
        ...item,
        title: l?.localization[item.id].title,
        abbr: l?.localization[item.id].abbr,
        link: asPath,
      })),
      active: locale,
      setActive: (locale: string) => {
        changeLocale(locale as LocaleType);
      },
      renderItemLink,
    }),
    [l, locale, changeLocale, asPath]
  );

  return {
    localization,
  };
};
