import { chessSide } from 'chessgun/core';
import { useEffect, useMemo } from 'react';

import { GameStatus, IBoardEndedData, ITimeControl } from '@types';
import { useCountdown } from './_useCountdown';
import { usePrevious } from '@store/context/lobby_context/hooks/_use_previous.hook';

const gameStatusesToIgnore = [
  GameStatus.INITIALIZING,
  GameStatus.CREATING,
  GameStatus.CREATED,
  GameStatus.SUBSCRIBED,
];

interface IUsePlayerTimeParams {
  timeControl?: ITimeControl | null;
  msLeft: number;
  gameStatus: GameStatus;
  playerTurn: chessSide;
  gameEnded: IBoardEndedData | boolean | null;
  playerColor?: chessSide;
  awaitingMove?: boolean;
  timeControlMs?: number | null;
  isGameDataLoaded?: boolean;
}

/**
 * Хук таймера игрока
 * Заботится об асинхронных вызовах updateTimer хука useCountdown
 * @param IUsePlayerTimeParams
 * @returns {number} milliseconds
 */
export const usePlayerTime = ({
  msLeft,
  gameStatus,
  playerTurn,
  gameEnded,
  playerColor,
  awaitingMove,
  timeControlMs,
  isGameDataLoaded = true,
}: IUsePlayerTimeParams) => {
  const playerTime = useMemo(() => {
    if (awaitingMove && timeControlMs) {
      return timeControlMs;
    }

    if (msLeft !== undefined) {
      return msLeft;
    }

    return 0;
  }, [msLeft, awaitingMove, timeControlMs]);

  const prevPlayerTime = usePrevious(playerTime);

  const { milliseconds, updateTimer, startTimer, stopTimer, paused } =
    useCountdown({
      timeInMilliseconds: playerTime,
      tickTime: 100,
      autostart: false,
    });

  const msIsEmpty = useMemo(() => !milliseconds, [milliseconds]);

  // Timer Control
  useEffect(() => {
    if (!playerColor) return;

    // Time Update
    if (playerTime !== undefined && playerTime !== prevPlayerTime) {
      updateTimer(playerTime);
    }

    if (msIsEmpty || gameStatusesToIgnore.includes(gameStatus)) {
      if (!paused) stopTimer();
      return;
    }

    if (gameEnded || awaitingMove) {
      stopTimer();
      return;
    }

    if (playerTurn === playerColor && isGameDataLoaded && !gameEnded) {
      startTimer(playerTime);
    } else {
      stopTimer();
    }
  }, [
    msIsEmpty,
    playerTurn,
    playerColor,
    gameStatus,
    gameEnded,
    playerTime,
    prevPlayerTime,
    awaitingMove,
    isGameDataLoaded,
  ]);

  return {
    milliseconds,
    paused,
  };
};
