import { useMemo } from 'react';
import { GameRatingMode, ITimeControl } from '@types';
import { ICurrentSettings } from '../../_types';
import { useCurrentGameData } from '@store/context/lobby_context/hooks/_use_current_game_data.hook';

/**
 * Хук возвращает текущие настройки пользователя
 * @returns
 */
export const useCurrentSettings = (): ICurrentSettings | null => {
  const gameData = useCurrentGameData();

  const currentSettings = useMemo<ICurrentSettings>(
    () => ({
      rating: gameData?.boardData?.rating || GameRatingMode.UNRATED,
      timeControl: gameData?.boardData?.time_control as ITimeControl,
    }),
    [gameData]
  );

  if (!gameData) return null;

  return currentSettings;
};
