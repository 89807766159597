import React, { memo, useMemo } from "react";
import { chessSide, flipTurn } from "../../core";
import { parseFen } from "../../core/fen/_parseFen";
import { cgDataTypes } from "../../ui";
import { eLastMoveTheme } from "../../ui/_settings.types";
import { LastMove } from "./_LastMove";
import { Pieces } from "./_Pieces";
import { Titles } from "./_Titles";
import { getChessboardSize } from "../../common/_getChessboardSize";
/**
 * Доска для стрима шахматной партии
 * На ней нельзя играть
 */
export const CGStreamBoard = memo(function CGStreamBoard({ theme, history, lmTheme = eLastMoveTheme.ARROW, chessboardSize = 400, rotate = false, showTitles = false, }) {
    const lastMoveLan = useMemo(() => {
        var _a;
        return ((_a = history[history.length - 1]) === null || _a === void 0 ? void 0 : _a.lan) || null;
    }, [history]);
    const lastTurn = useMemo(() => {
        const lastFen = history[history.length - 1];
        if (!lastFen)
            return chessSide.WHITE;
        const { nextTurn } = parseFen(lastFen.fen);
        return flipTurn(nextTurn);
    }, [history]);
    const boardSize = useMemo(() => getChessboardSize(chessboardSize), [chessboardSize]);
    return (React.createElement("div", { className: theme, "data-cg-type": cgDataTypes.BOARD, "data-theme": theme, style: {
            width: boardSize,
            height: boardSize,
            overflow: "hidden",
            borderRadius: "3px",
            transform: `rotate(${rotate ? 180 : 0}deg)`,
        } },
        lastMoveLan !== null && (React.createElement(LastMove, { theme: lmTheme, lan: lastMoveLan, turn: lastTurn, chessboardSize: boardSize })),
        React.createElement(Pieces, { history: history, rotate: rotate }),
        showTitles && React.createElement(Titles, { chessboardSize: boardSize, rotate: rotate })));
});
