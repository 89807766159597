import { fideIdPlan } from '@constants/_plans.constants';
import { useUserDataStore } from '@store/storeshed';
import { eAccessLevel, ePaymentSource } from '@types';

export const useIsProStripeSubscription = () => {
  const userData = useUserDataStore('data');

  const accessLevel = userData?.access_level;
  const paymentSource = userData?.recurring_purchase?.payment_source;
  const subscriptions = userData?.subscriptions;
  const fideSubscription = subscriptions?.find(
    (item) => item.plan.stripe_id === fideIdPlan
  );
  const fideSubscriptionIsActive = fideSubscription?.status === 'active';

  return (
    accessLevel === eAccessLevel.PRO &&
    (paymentSource === ePaymentSource.STRIPE || fideSubscriptionIsActive)
  );
};
