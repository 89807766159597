import { LocaleType } from '@types';
import { routesConstants } from '@constants';

export const headerHome = {
  id: 'home',
  title: '',
  link: routesConstants.HOME,
};

export const headerMenu = {
  items: [
    {
      id: 'lobby',
      title: '_l.header.menu.items.lobby',
      link: routesConstants.LOBBY,
      prefetch: false,
    },
    {
      id: 'tournaments',
      title: '_l.header.menu.items.turnaments',
      link: routesConstants.TOURNAMENTS,
      prefetch: false,
    },
    {
      id: 'puzzles',
      title: '_l.header.menu.items.puzzles',
      link: routesConstants.PUZZLES,
      prefetch: false,
    },
    {
      id: 'masterclasses',
      title: '_l.header.menu.items.masterclasses',
      link: routesConstants.MASTERCLASSES,
      prefetch: false,
    },
    {
      id: 'players',
      title: '_l.header.menu.items.players',
      link: routesConstants.PLAYERS,
      prefetch: false,
    },
    {
      id: 'community',
      title: '_l.header.menu.items.clubs',
      link: routesConstants.COMMUNITY,
      prefetch: false,
    },
    {
      id: 'watch',
      title: '_l.header.menu.items.watch',
      link: routesConstants.BROADCAST_LOBBY,
      prefetch: false,
    },
    {
      id: 'shop',
      title: '_l.header.menu.items.shop',
      href: 'https://shop.worldchess.com/',
    },
    {
      id: 'coaches',
      title: '_l.header.menu.items.coaches',
      href: 'https://coaches.chessarena.com',
    },
  ],
};

export const headerSubMenu = {
  broadcasts: [
    {
      id: 'live_and_upcoming',
      title: '_l.header.sub_menu.live_and_upcoming',
      link: routesConstants.BROADCAST_LOBBY,
    },
    {
      id: 'history',
      title: '_l.header.sub_menu.history',
      link: routesConstants.BROADCAST_HISTORY,
    },
  ],
  armageddon: [
    {
      id: 'armageddon_grand_finale',
      title: '_l.header.sub_menu.armageddon_grand_finale',
      link: routesConstants.ARMAGEDDON_FINALE,
    },
    {
      id: 'armageddon_previous',
      title: '_l.header.sub_menu.armageddon_previous',
      link: routesConstants.ARMAGEDDON,
    },
  ],
};

export const headerLocalization = {
  items: [
    {
      id: LocaleType.EN,
      title: '_l.localization.english',
      abbr: 'EN',
    },
    {
      id: LocaleType.RU,
      title: '_l.localization.russian',
      abbr: 'RU',
    },
    {
      id: LocaleType.KZ,
      title: '_l.localization.kazakh',
      abbr: 'KZ',
    },
  ],
};

export const headerAccountPopupItems = {
  profile: {
    id: 'profile',
    title: 'l.header.account.popup.items.profile.title',
    link: routesConstants.PROFILE,
  },
  subscription: {
    id: 'subscription',
    title: 'l.header.account.popup.items.subscription.title.upgrade',
    description:
      'l.header.account.popup.items.subscription.description.upgrade',
  },
  fide_id: {
    id: 'fideId',
    title: 'l.header.account.popup.items.fide_id.title.not_connected',
    description:
      'l.header.account.popup.items.fide_id.description.not_connected',
  },
  settings: {
    id: 'settings',
    title: 'l.header.account.popup.items.settings.title',
    description: 'l.header.account.popup.items.settings.description',
    link: routesConstants.INFO_BIO,
  },
  signOut: {
    id: 'signOut',
    title: 'l.header.account.popup.items.signOut.title',
  },
  signIn: {
    id: 'signIn',
    title: 'l.header.account.popup.items.signIn.title',
  },
  register: {
    id: 'register',
    title: 'l.header.account.popup.items.register.title',
  },
};
