import React, { useMemo } from 'react';
import Link from 'next/link';
import {
  AccountPopupItem,
  AccountPopupRenderItemLinkParams,
} from '@chessarena/components/lib/layout/AccountPopup/types';
import { useApplicationContext } from '@application';
import { ePopupPaths, routesConstants } from '@constants';
import { eFideIdStatus, eAccessLevel } from '@types';
import { headerAccountPopupItems } from './_constants';
import { loginActions } from '@store/storeshed';
import { useAuthStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { openPopup } from '@utils/_router';
import { formatLabel } from '@utils/_formatLabel';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { useRedirectToCustomerPortal } from '@utils/hooks/_useRedirectToCustomerPortal.hook';
import { useIsProStripeSubscription } from '@utils/hooks/_useIsProStripeSubscription.hook';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

export const useCustomHeaderAccount = () => {
  const { localization: l } = useApplicationContext();

  const { onCustomerPortalClick } = useRedirectToCustomerPortal();
  const fideIdStatus = useFideIdStatus();

  const userDataRequest = useUserDataStore('data_request');
  const userData = useUserDataStore('data');
  const logged = useAuthStore('logged');
  const accessLevel = userData?.access_level;
  const fideId = userData?.fide_id;
  const isProStripeSubscription = useIsProStripeSubscription();

  const accountPopupFideId: AccountPopupItem | null = useMemo(() => {
    if (
      accessLevel !== eAccessLevel.PRO &&
      fideIdStatus === eFideIdStatus.NOT_CONNECTED
    )
      return null;

    const itemLocalization = l.header.account.popup.items.fide_id;

    switch (fideIdStatus) {
      case eFideIdStatus.CONNECTED:
        return {
          ...headerAccountPopupItems.fide_id,
          title: formatLabel(itemLocalization.title.connected, {
            id: fideId ?? '',
          }),
          description: itemLocalization.description.connected,
          href: `https://ratings.fide.com/profile/${fideId}`,
        };
      case eFideIdStatus.ON_CHECK:
        return {
          ...headerAccountPopupItems.fide_id,
          title: itemLocalization.title.waiting_for_approval,
          description: itemLocalization.description.waiting_for_approval,
        };
      case eFideIdStatus.REJECTED:
        return {
          ...headerAccountPopupItems.fide_id,
          title: itemLocalization.title.was_rejected,
          description: itemLocalization.description.was_rejected,
          attention: 'membership',
        };
      case eFideIdStatus.NOT_CONNECTED:
        return {
          ...headerAccountPopupItems.fide_id,
          title: itemLocalization.title.not_connected,
          description: itemLocalization.description.not_connected,
          attention: 'claim',
        };

      default:
        return null;
    }
  }, [l, fideIdStatus, fideId, accessLevel]);

  const account = useMemo(() => {
    const links: AccountPopupItem[] = [];

    const auth: AccountPopupItem[] = [];

    if (logged) {
      const accountPopupProfile = {
        ...headerAccountPopupItems.profile,
        title: l.header.account.popup.items.profile.title,
        link: `${headerAccountPopupItems.profile.link}/${userData?.player?.player_id}`,
      };
      links.push(accountPopupProfile);

      const accountSubscriptionTitle =
        accessLevel === eAccessLevel.PRO
          ? l.header.account.popup.items.subscription.title.pro
          : l.header.account.popup.items.subscription.title.upgrade;
      let accountSubscriptionDescription =
        accessLevel === eAccessLevel.PRO
          ? l.header.account.popup.items.subscription.description.pro
          : l.header.account.popup.items.subscription.description.upgrade;
      if (!isProStripeSubscription) {
        accountSubscriptionDescription = undefined;
      }
      const accountSubscriptionLink =
        accessLevel !== eAccessLevel.PRO ? routesConstants.PRICING : undefined;

      const accountPopupSubscription: AccountPopupItem = {
        ...headerAccountPopupItems.subscription,
        title: accountSubscriptionTitle,
        description: accountSubscriptionDescription,
        link: accountSubscriptionLink,
      };
      links.push(accountPopupSubscription);

      if (accountPopupFideId) {
        links.push(accountPopupFideId);
      }

      const accountPopupSettings = {
        ...headerAccountPopupItems.settings,
        title: l.header.account.popup.items.settings.title,
        description: l.header.account.popup.items.settings.description.default,
      };
      links.push(accountPopupSettings);

      const accountPopupSignOut = {
        ...headerAccountPopupItems.signOut,
        title: l.header.account.popup.items.signOut.title,
      };
      auth.push(accountPopupSignOut);
    } else {
      const accountPopupSignIn = {
        ...headerAccountPopupItems.signIn,
        title: l.header.account.popup.items.signIn.title,
      };
      auth.push(accountPopupSignIn);

      const accountPopupRegister = {
        ...headerAccountPopupItems.register,
        title: l.header.account.popup.items.register.title,
      };
      auth.push(accountPopupRegister);
    }

    const onSubscriptionClick = isProStripeSubscription
      ? onCustomerPortalClick
      : () => {};
    const onFideIdClick =
      fideIdStatus === eFideIdStatus.NOT_CONNECTED
        ? () => openPopup(`?popup=${ePopupPaths.FIDE}`)
        : () => {};

    const handleItemClick = (id: string) => {
      switch (id) {
        case 'signOut':
          gtmPush({
            event: GTM_EVENTS.USER_IS_LOGGED_OUT,
            paramUserId: userData?.player.player_id,
          });
          loginActions.signOut();
          break;
        case 'signIn':
          window.scrollTo({ top: 0, behavior: 'smooth' });
          openPopup(`?popup=${ePopupPaths.SIGN_IN}`);
          break;
        case 'register':
          openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
          break;
        case 'subscription':
          onSubscriptionClick();
          break;
        case 'fideId':
          onFideIdClick();
          break;
        default:
          break;
      }
    };

    const renderItemLink = (params: AccountPopupRenderItemLinkParams) => {
      const { link, children, ...rest } = params;

      return (
        <Link href={link} {...rest} prefetch={false}>
          {children as React.ReactNode}
        </Link>
      );
    };

    return {
      loading: !!userDataRequest,
      avatar: logged
        ? {
            src: userData?.avatar?.small || undefined,
            premium: userData?.access_level === eAccessLevel.PRO,
          }
        : undefined,
      links,
      auth,
      onItemClick: handleItemClick,
      renderItemLink: renderItemLink,
    };
  }, [
    logged,
    isProStripeSubscription,
    onCustomerPortalClick,
    fideIdStatus,
    userDataRequest,
    userData,
    l.header.account.popup.items,
    accessLevel,
    accountPopupFideId,
  ]);

  return {
    account,
  };
};
