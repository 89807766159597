import { chessSide, getMoveByAddress } from 'chessgun/core';
import { flipTurn } from 'chessgun/core/common/_flipTurn';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { GameUserType, SoundType } from '@types';
import { debounce } from 'shared/helpers/_common';
import { getFiguresAdvantageFromFen } from 'shared/helpers/_game';
import { GameStatus, IBoardData, TournamentBoardStatus } from '@types';
import { useNGPageActions } from './_actions';
import { NGPageContextReducer, initState } from './_reducer';
import { INGPageContextProps } from './_types';
import { useChessboardSize, useNGContextComputed } from './hooks';
import { soundsActions } from '@store/storeshed';
import { gtmFind } from '@utils/_gtm';
import { ePhase } from '@store/context/_common.types';
import { eDrawRule } from '@store/context/_types';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { IGameReactionsData } from '../_types';
import { useReactions } from './hooks/_use_reactions.hook';
import { getLSCapturedFiguresView } from '../helpers/_capturedFiguresViewLocalStorage';
import { useAlertPopup } from '@utils/hooks/_useAlertPopup.hook';

export const NGPageContext = React.createContext({} as INGPageContextProps);

export type INGPageContextProvier = React.PropsWithChildren<{
  id: string;
  data?: IBoardData;
  reactionsData: IGameReactionsData;
}>;

export const NGPageContextProvider: React.FC<INGPageContextProvier> = memo(
  function NGPageContextProvider({ id, children, data, reactionsData }) {
    // const sounds = useBoardSettingsData('soundsTheme');
    // const premoves = useBoardSettingsData('premovesTheme');
    // const proSubscription = useFideSubscriptionStore('pro_subscription');

    const [state, dispatch] = React.useReducer(NGPageContextReducer, {
      ...initState,
      id,
      timeControl: data?.time_control,
      figuresView: getLSCapturedFiguresView(),
    });
    const alertPopup = useAlertPopup();
    const actions = useNGPageActions(dispatch, state, alertPopup);

    const [playerTurn, setPlayerTurn] = useState<chessSide>(chessSide.WHITE);

    const {
      state: { inviteData },
      actions: { updateGameRequestState },
    } = useLobbyContext();

    const {
      currPh,
      myColor,
      currentGame,
      whitePreMoveMsLeft,
      blackPreMoveMsLeft,
      whitePlayer,
      blackPlayer,
      endReason,
      isAbort,
      result,
      gameUserType,
      engine,
      analysis,
      boardDataRequest,
      boardData,
      gameStatus,
      uid,
      opponentMode,
      gameType,
      playerWsStatus,
      opponentDisconnected,
      gameRatingMode,

      isMultiregion,
      dateTimeOfRound,
      tournamentId,
      tournamentSlug,
      turns,
      currentSettings,
      lossIfDisconnectedForTime,
      opponentDisconnectedLoseTs,
      opponent,
    } = useNGContextComputed(actions);
    const chessboardSize = useChessboardSize(gameType);

    const draws = useMemo(() => {
      const draw = currentGame?.state?.playingPh?.draw ?? [];

      return draw.join();
    }, [currentGame?.state?.playingPh?.draw]);

    const debounceUpdate = debounce(() => {
      if (!engine) return;

      // обращаемся к истории напрямую, т.к. при премуве
      // реакт ломает порядок обновления истории
      const history = engine.get().history;
      actions.setMovesHistory(history);
      actions.setMovesHistoryLength(history.length);

      if (history.length) {
        const historyLastMove = history[history.length - 1];

        actions.setLastMove(historyLastMove);
        actions.setSelectedMove(historyLastMove);
        actions.setGameReviewMode(false);

        if (historyLastMove) {
          setPlayerTurn(flipTurn(historyLastMove.turn));
        }
      }
    }, 20);

    const reactions = useReactions(
      reactionsData,
      currentGame,
      myColor,
      gameUserType,
      gameStatus,
      state.movesHistoryLength
    );

    useEffect(
      function subscribeToNewEngine() {
        if (!engine) return;

        const unsubLastMove = engine.on('history', debounceUpdate);

        const unsubFen = engine.on('currentFen', (currentFen) => {
          const movesHistory = engine.get().history;
          const selectedMoveAddress = engine.get().selectedMoveAddress;
          const currentMove = getMoveByAddress(
            movesHistory,
            selectedMoveAddress
          );
          if (currentMove) {
            actions.setBlackCaptured(
              currentMove.capturedFigures[chessSide.BLACK]
            );
            actions.setWhiteCaptured(
              currentMove.capturedFigures[chessSide.WHITE]
            );

            const turn = engine.get().turn;
            const advantage = getFiguresAdvantageFromFen(currentFen, turn);
            actions.setBlackAdvantage(advantage.black);
            actions.setWhiteAdvantage(advantage.white);
          }
        });

        return () => {
          unsubLastMove();
          unsubFen();
        };
      },
      [engine]
    );

    useEffect(
      function setupSendingMoveToServer() {
        if (!engine || !currentGame) return;

        const unsubHistory = engine.on('history', (h, ph) => {
          if (h.length === 0 || h.length === ph.length) return;

          const lastMove = h[h.length - 1];

          if (!lastMove) return;

          if (lastMove.turn !== myColor) return;

          // Not a part of business logic anymore
          // if (state.drawOffered) {
          //   currentGame.ws.send(eGameServerPayloadType.DRAW_OFFER);
          //   actions.setDrawOfferSended(true);
          // }
        });

        return unsubHistory;
      },
      [engine, myColor]
    );

    useEffect(
      function updateDrawInfo() {
        if (gameUserType !== GameUserType.PLAYER) return;

        const drawsList = currentGame?.state?.playingPh?.draw;

        if (state.opponentOfferedDraw) {
          actions.setOpponentOfferedDraw(false);
          actions.setThreefoldRepetition(false);
        }

        if (state.drawOfferSended && !drawsList?.length) {
          actions.setDrawOffered(false);
          actions.setDrawOfferSended(false);
          soundsActions.playSound(SoundType.drawdecl);
        }

        if (drawsList?.length && currentGame?.state?.cause === 'DRAW_OFFER') {
          const setPlayerOfferedADraw = () => {
            actions.setDrawOffered(true);
            actions.setDrawOfferSended(true);
          };

          const setOpponentOfferedADraw = () => {
            soundsActions.playSound(SoundType.draw);
            actions.setOpponentOfferedDraw(true);
          };

          const setDrawOffer = (isPlayer: boolean) => {
            isPlayer ? setPlayerOfferedADraw() : setOpponentOfferedADraw();
          };

          drawsList.forEach((draw) => {
            switch (draw) {
              case eDrawRule.DRAW_OFFER_WHITE:
              case eDrawRule.DRAW_OFFER_BLACK:
                const playerDrawRule =
                  myColor === chessSide.WHITE
                    ? eDrawRule.DRAW_OFFER_WHITE
                    : eDrawRule.DRAW_OFFER_BLACK;

                setDrawOffer(draw === playerDrawRule);

                break;

              case eDrawRule.DRAW_50_MOVES:
              case eDrawRule.DRAW_3_FOLD_REPETITION:
                soundsActions.playSound(SoundType.draw);
                actions.setOpponentOfferedDraw(true);
                actions.setThreefoldRepetition(true);
                break;

              default:
                break;
            }
          });
        }
      },
      [draws, myColor]
    );

    useEffect(() => {
      if (
        typeof currPh !== 'undefined' ||
        typeof boardData?.status !== 'undefined'
      ) {
        actions.setIsGameDataLoaded(true);
      }
    }, [boardData?.status, currPh, id]);

    useEffect(() => {
      const gameEnded =
        currPh === ePhase.DONE ||
        boardData?.status === TournamentBoardStatus.COMPLETED;

      actions.setGameEnded(gameEnded);

      if (gameEnded) {
        actions.setDrawOffered(false);
        actions.setDrawOfferSended(false);
      }
    }, [boardData?.status, currPh]);

    useEffect(() => {
      setPlayerTurn(chessSide.WHITE);
      updateGameRequestState(null);

      return () => {
        actions.resetGame();
        updateGameRequestState(null);
      };
    }, [id]);

    // если меняется таймконтрол между играми — обновляем его
    useEffect(() => {
      if (
        data?.time_control &&
        state.timeControl?.id !== data.time_control.id
      ) {
        actions.setTimeControl(data.time_control);
      }
    }, [actions, data?.time_control, state.timeControl?.id]);

    // если меняется id между играми — обновляем его
    useEffect(() => {
      if (id && state.id !== id) {
        actions.setId(id);
      }
    }, [actions, id, state.id]);

    // сброс статусов анимаций после редиректа на другую партию
    useEffect(
      function setupAnimationsForNewGame() {
        if (!state.gameEnded) {
          actions.setEndedAnimationShown(false);
          actions.setTopWarnOpacity(0);
          actions.setBottomWarnOpacity(0);

          actions.setDrawOffered(false);
          actions.setDrawOfferSended(false);
        }
      },
      [state.gameEnded]
    );

    useEffect(() => {
      if (!inviteData || gameStatus !== GameStatus.STARTED) return;

      const alreadyPushed = !!gtmFind('game', id);
      if (alreadyPushed) return;
    }, [inviteData, id, gameStatus]);

    return (
      <NGPageContext.Provider
        value={{
          state,
          actions,
          dispatch,
          computed: {
            currentGame,
            myColor,
            whitePreMoveMsLeft,
            blackPreMoveMsLeft,
            playerTurn,
            whitePlayer,
            blackPlayer,
            endReason,
            isAbort,
            result,
            gameType,
            gameUserType,
            engine,
            boardData,
            boardDataRequest,
            analysis,
            gameStatus,
            uid,
            boardId: id,
            opponentMode,
            chessboardSize,
            playerWsStatus,
            opponentDisconnected,
            gameRatingMode,
            isMultiregion,
            dateTimeOfRound,
            tournamentId,
            tournamentSlug,
            turns,
            currentSettings,
            lossIfDisconnectedForTime,
            opponentDisconnectedLoseTs,
            opponent,
            // datetimeOfCancel: null,
          },
          reactions,
          alertPopup,
        }}
      >
        {children}
      </NGPageContext.Provider>
    );
  }
);

export const useNGPageContext = () => useContext(NGPageContext);
