import React from 'react';

import { RatingType } from '@chessarena/components/lib/common/types';
import { ButtonType } from '@chessarena/components/lib/general/Button/types';

import { IChallengeItem, ILocalization } from '@types';

const getTimeControl = (name: string) => {
  const data = name.split(' ');
  return { type: data[0], time: data.slice(1).join(' ') };
};

export const getChellengesItems = ({
  items,
  l,
  renderBoard,
  userChallengesRequest,
}: {
  items?: IChallengeItem[];
  l: ILocalization;
  renderBoard: () => React.ReactNode;
  userChallengesRequest?: boolean;
}) => {
  if (!items) return [];

  return items.map((item) => {
    return {
      id: item.id,
      player: {
        fullName: item.owner.full_name || '',
        rating: item.owner.rating,
      },
      timeControl: getTimeControl(item.time_control.name),
      ratingType: item.rating_type as RatingType,
      ratingName: l.ratings[item.rating_type],
      actions: [
        {
          id: 'accept',
          type: 'primary' as ButtonType,
          label: l.challenges.actions.accept,
          disabled: userChallengesRequest,
        },
        {
          id: 'decline',
          type: 'accent' as ButtonType,
          label: l.challenges.actions.decline,
          disabled: userChallengesRequest,
        },
      ],
      renderBoard,
    };
  });
};
